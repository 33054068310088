import React from "react"
import styled, {css} from 'styled-components';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { BasicPageHeading } from '../components/PageHeading';
import { TextSection, SectionHeading, Declaration, UL, LI } from '../components/textComponents';

import theme from './../data/theme';

const keywords = [
  'VLP',
  'Landscaping',
  'Landscaper',
  'Matt Vojticek',
  'Customer Service',
  'Northeast Ohio',
];

const page = {
  title: 'Page Not Found',
  keywords: keywords,
  description: 'If you have a landscaping or lawn maintenance project, VLP Landscaping is here to help.  Visit our home page to see more!',
};

const Wrapper = styled.div`
  width: ${ theme.contentWidth };
  max-width: ${ theme.maxContentWidth };
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
`;

const StyledLink = styled(Link)`
  color: ${ theme.colors.primary };
  text-decoration: none;
  font-weight: 600;

  &:hover {
    border-bottom: 2px solid;
  }
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title={page.title} keywords={page.keywords}/>
    <BasicPageHeading page={page}/>
    <Wrapper>
      <TextSection css={`
        margin-bottom: 40rem;
      `}>
        <Declaration>
          This page no longer exists. <StyledLink to="/">Return to Home Page</StyledLink>
        </Declaration>
      </TextSection>
    </Wrapper>
  </Layout>
)

export default NotFoundPage;
